

















































import { defineComponent, PropType } from "@vue/composition-api";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import {
  IERCProtocolsCardElement,
  ICommissionMemberList,
} from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/types/ERCProtocolsCardElement";
import { mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

const tableHeaders = [
  { defaultWidth: 250, value: "fullName", text: "Фамилия Имя Отчество", tooltip: "Фамилия Имя Отчество" },
  { defaultWidth: 150, value: "status", text: "Статус", tooltip: "Статус" },
  { defaultWidth: 79, value: "additionalSlot", text: "", tooltip: "" },
];

export default defineComponent({
  name: "FormCommisionsComposition",
  components: {
    ApproveTable: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewApproveTable" */
        "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/ApproveTable.vue"
      ),
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object as PropType<Partial<IERCProtocolsCardElement>>,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tableHeaders,
      cardMode: CardMode,
      section: Sections.INVENTORY_PROJECT,
    };
  },
  computed: {
    ...mapGetters("auth", ["cardModeList"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    approveList(): { id: number; fullName: string; status: string }[] {
      return (
        this.element?.expertCommissionDto?.commissionMemberList
          ?.filter((item: ICommissionMemberList) => item.memberKind?.code === "APPROVING")
          .map((item: ICommissionMemberList) => ({
            id: item.id,
            fullName: item.personFullName,
            status: item.approvedState?.code || "",
          })) || []
      );
    },
    coordinatingList(): { id: number; fullName: string; status: string }[] {
      return (
        this.element?.expertCommissionDto?.commissionMemberList
          ?.filter((item: ICommissionMemberList) => item.memberKind?.code === "COORDINATING")
          .map((item: ICommissionMemberList) => ({
            id: item.id,
            fullName: item.personFullName,
            status: item.approvedState?.code || "",
          })) || []
      );
    },
  },
  methods: {
    openAddApproveModal(id: string, isMainPerson?: boolean) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "AddApproveModal", {
          props: { id },
          isMainPerson,
          cbOnAddApprove: (fullName: string) => {
            console.log(fullName, id);
          },
        })
      );
    },
  },
});
